document.addEventListener("DOMContentLoaded", () => {
  const sliders = document.querySelectorAll(".Slider");

  if (!sliders.length) return;

  sliders.forEach((sliderEl) => {
    if (!sliderEl.getClientRects().length) return;

    const intervals = [];
    let rollingTextContainer = sliderEl.querySelector(".Text-Slider");
    let baseTextElement = sliderEl.querySelector(".Text-element");
    let rollingTextContainerWidth = window.innerWidth;
    let baseTextElementWidth = baseTextElement.getClientRects()[0].width;
    const allTextElements = [baseTextElement];
    let index = 1;

    function copyBaseTextElement() {
      const newElement = document.createElement("h1");
      newElement.textContent = baseTextElement.textContent;
      newElement.className = baseTextElement.className;
      // eslint-disable-next-line no-plusplus
      newElement.setAttribute("data-index", index++);
      rollingTextContainer.appendChild(newElement);
      return newElement;
    }

    function rollElement(timePassed, element) {
      const newX =
        -(timePassed / 10) +
        baseTextElementWidth * element.getAttribute("data-index");
      element.style.transform = `translateX(${newX}px)`;
      if (
        newX + baseTextElementWidth < 0 &&
        rollingTextContainer.contains(element)
      ) {
        clearInterval(intervals[0]);
        intervals.splice(1);
        rollingTextContainer.removeChild(element);
        const newElement = copyBaseTextElement();
        intervals.push(
          setInterval(() => {
            requestAnimationFrame((time) => rollElement(time, newElement));
          }, 10)
        );
      }
    }

    function updateSetUp() {
      rollingTextContainer = sliderEl.querySelector(".Text-Slider");
      baseTextElement = sliderEl.querySelector(".Text-element");
      rollingTextContainerWidth = window.innerWidth;
      baseTextElementWidth = baseTextElement.getClientRects()[0].width;

      const elementsNeededToFillWidth = Math.floor(
        rollingTextContainerWidth / baseTextElementWidth
      );

      for (let i = 0; i < elementsNeededToFillWidth + 1; i += 1) {
        const newElement = copyBaseTextElement();
        allTextElements.push(newElement);
      }

      allTextElements.forEach((textElement, position) => {
        intervals.push(
          setInterval(() => {
            requestAnimationFrame((time) =>
              rollElement(time, textElement, position)
            );
          }, 10)
        );
      });
    }

    window.addEventListener("focus", () => {
      updateSetUp();
    });

    window.onresize = () => {
      if (
        window.innerWidth >
        baseTextElementWidth * (allTextElements.length - 1)
      ) {
        updateSetUp();
      }
    };

    updateSetUp();
  });
});
