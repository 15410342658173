export default function setScrollbarWidth() {
  const root = document.documentElement;
  const body = document.querySelector("body");
  if (window.innerWidth > body.clientWidth + 5) {
    body.classList.add("has-scrollbar");
    root.style.setProperty(
      "--scroll-bar",
      `${window.innerWidth - body.clientWidth}px`
    );
  } else {
    body.classList.remove("has-scrollbar");
    root.style.setProperty("--scroll-bar", `${0}px`);
  }
}
