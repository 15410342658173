/**
 * Renders only changed productions
 *
 * @param {Array.<String>} next
 * @param {NodeList} prev
 */
function diffRender(next, prev) {
  const dom = prev.children;
  const parser = new DOMParser();

  if (next.length && dom[0].dataset.empty) {
    dom[0].parentNode.removeChild(dom[0]);
  }

  next.forEach((html, index) => {
    const doc = parser.parseFromString(html, "text/html");
    const item = doc.body.firstChild;

    if (!dom[index]) {
      prev.appendChild(item);
    } else if (
      dom[index].querySelector("a").href !== item.querySelector("a").href ||
      item.classList.toString() !== dom[index].classList.toString()
    ) {
      prev.children[index].insertAdjacentElement("beforebegin", item);
    }
  });

  let count = dom.length - next.length;
  if (count > 0) {
    for (; count > 0; count -= 1) {
      dom[dom.length - count].parentNode.removeChild(dom[dom.length - count]);
    }
  }
}

document.addEventListener("DOMContentLoaded", () => {
  document
    .querySelectorAll("[data-cals-productions]")
    .forEach(async (element) => {
      const result = await fetch("/wp-json/cals/v1/productions");
      const data = await result.json();
      const { productions, filters } = data;
      let filterSelections = {
        artforms: [],
        groups: [],
        locations: [],
      };
      const productionFilter = element.querySelector(
        "[data-cals-production-filter]"
      );
      const productionList = element.querySelector(
        "[data-cals-production-list]"
      );
      const selectedList = productionList.innerHTML;

      const html = `
        ${Object.entries(filters)
          .map(
            ([filter, { label, items }]) => `
              <button class="max-[768px]:hidden Dropdown-check-list">
                <span class="Filter-selections border-b border-lilac/25 md:hover:bg-hoverlilac md:border-b-0">
                  ${label}
                  <span class="Filter-indicator" id="filter-indicator--${filter}"></span>
                </span>
                <ul class="Items m-0">
                ${items
                  .map(
                    ({ name, slug }) => `
                    <li>
                      <label class="Dropdown-label Tag flex gap-2 justify-between items-center whitespace-nowrap py-2 pl-6 md:pl-0">
                        ${name}
                        <input type="checkbox" name="${name}" value="${slug}" data-category=${filter} class="Dropdown-checkbox" />
                      </label>
                    </li>`
                  )
                  .join("")}
                </ul>
              </button>
            `
          )
          .join("")}
      `;

      const mobileFilterButton = `
        <div class="Mobile-filter--menu md:hidden hidden bg-offwhite text-burgundy text-center p-4 order-first">
          <div class="flex justify-between items-center">
            <h5 class="uppercase">
              Filtrera
            </h5>
            <span class="Mobile-filter Close-button"></span>
          </div>  
        </div>
        <div class="w-full bg-offwhite">
          
          <div class="Mobile-filter--wrapper flex justify-center mb-4 md:mb-0 md:hidden bg-palelilac text-burgundy text-center order-last">
            
            <button data-toggle="open" class="Mobile-filter flex flex-wrap justify-center gap-2 w-full h-full p-4 mr-1">
              <span class="Mobile-filter-text">Filtrera produktioner</span>
              <span id="filter-indicator--mobile"></span>
            </button>
          </div>

          <li class="Filter-blob Clear-filter Clear-filter--mobile md:hidden hidden uppercase justify-center mt-auto order-last" id="Clear-filter">
            <span class="icon-bin"></span>  
            Rensa filter
          </li>
        </div>
      `;

      productionFilter.innerHTML = html + mobileFilterButton;

      const listButton = element.querySelector(".List-button");
      const checkboxes = element.querySelectorAll(".Dropdown-checkbox");
      const checkLists = element.querySelectorAll(".Dropdown-check-list");
      const filtersElement = element.querySelector(".Selected-filters");
      const mobileFilterWrapper = element.querySelector(
        ".Mobile-filter--wrapper"
      );
      const mobileFilter = element.querySelectorAll(".Mobile-filter");
      const mobileFilterMenu = element.querySelector(".Mobile-filter--menu");
      const clearMobileFilter = element.querySelector(".Clear-filter--mobile");
      const indicatorTextMobile = element.querySelector(
        "#filter-indicator--mobile"
      );

      // Toggle dropdown list for each filter-type
      document.addEventListener("click", ({ target }) => {
        if (target.classList.contains("Filter-selections")) {
          target.closest(".Dropdown-check-list").classList.toggle("Visible");
          checkLists.forEach((list) => {
            if (list !== target.closest(".Dropdown-check-list")) {
              list.classList.remove("Visible");
            }
          });
        } else if (!target.closest(".Dropdown-check-list")) {
          checkLists.forEach((list) => list.classList.remove("Visible"));
        }
      });

      const renderFilterBlobs = () => {
        Object.keys(filterSelections).forEach((key) => {
          const indicator = element.querySelector(`#filter-indicator--${key}`);
          indicator.innerHTML = filterSelections[key].length
            ? filterSelections[key].length
            : "";
        });

        if (Object.values(filterSelections).every((n) => !n.length)) {
          filtersElement.innerHTML = "";
        } else {
          const resultHtml = [
            `<li class='Filter-blob Clear-filter m-0 md:hover:bg-hoverlilac' id="Clear-filter">
              <span class="icon-bin"></span>  
              Rensa filter
            </li>`,
          ];
          const listItems = Object.keys(filterSelections).flatMap((key) =>
            filterSelections[key].map(
              (filterName) => `<li class='Filter-blob Selected-filter' 
                          data-category=${key} 
                          data-name=${filterName.replace(" ", "-")}
                          id=${filterName.toLowerCase().replace(" ", "-")}>
                         <span class='Close'>${filterName}</span>
                      </li>`
            )
          );

          filtersElement.innerHTML = resultHtml.join("") + listItems.join("");
        }
      };

      const fixSafariImageBug = () => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent
        );
        if (!isSafari) return;
        document.querySelectorAll("img.MobileImage").forEach((img) => {
          const srcset = img.getAttribute("srcset");
          img.setAttribute("srcset", "");
          img.setAttribute("srcset", srcset);
        });
      };

      const renderProductions = () => {
        let filteredProductions = [...productions];

        Object.keys(filterSelections).forEach((filterKey) => {
          if (!filterSelections[filterKey].length) {
            return;
          }
          filteredProductions = filteredProductions.filter((production) =>
            filterSelections[filterKey].some((f) =>
              production[filterKey].some((pf) => pf.name === f)
            )
          );
        });

        indicatorTextMobile.innerHTML =
          filteredProductions.length === productions.length
            ? ""
            : `(${filteredProductions.length})`;

        if (filteredProductions.length) {
          const productionHtml = filteredProductions.map(
            (production) => production.html
          );

          diffRender(productionHtml, productionList);
          fixSafariImageBug();
        } else {
          productionList.innerHTML = `<p data-empty="true" class="text-center p-8">Inga produktioner matchade ditt urval</p>`;
        }

        const event = new Event("productionsUpdated");
        window.dispatchEvent(event);
      };

      const displayCheck = ({ target }) => {
        if (target.checked) {
          target.parentElement.classList.add("Dropdown-label--selected");
          filterSelections[target.dataset.category].push(target.name);
        } else {
          target.parentElement.classList.remove("Dropdown-label--selected");

          const indexOfObject = filterSelections[
            target.dataset.category
          ].indexOf(target.name);

          filterSelections[target.dataset.category].splice(indexOfObject, 1);
        }

        renderFilterBlobs();
        renderProductions();
      };

      element.addEventListener("change", displayCheck);

      // Remove filter-blob
      element.addEventListener("click", (e) => {
        const target = e.target.closest(".Selected-filter");

        if (target) {
          checkboxes.forEach((checkbox) => {
            if (checkbox.name.toLowerCase().replace(" ", "-") === target.id) {
              checkbox.checked = false;
            }
          });

          const indexOfObject = filterSelections[
            target.dataset.category
          ].indexOf(target.dataset.name);
          filterSelections[target.dataset.category].splice(indexOfObject, 1);

          renderFilterBlobs();
          renderProductions();
        }
      });

      // Remove all filters
      element.addEventListener("click", (e) => {
        const target = e.target.closest(".Clear-filter");

        const labels = document.querySelectorAll(".Dropdown-label");
        labels.forEach((label) => {
          label.classList.remove("Dropdown-label--selected");
        });

        if (target) {
          checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
          });
          filterSelections = {
            artforms: [],
            groups: [],
            locations: [],
          };
          renderFilterBlobs();
          renderProductions();
        }
      });

      // Toggle between list layout and selected list
      listButton.addEventListener("click", () => {
        const { toggle } = listButton.dataset;
        if (toggle === "list") {
          listButton.innerHTML =
            "<span class='icon-selected-list'></span> Visa utvalda";
          listButton.dataset.toggle = "selected";
        } else {
          listButton.innerHTML =
            "<span class='icon-list'></span> Visa som lista";
          listButton.dataset.toggle = "list";
        }

        if (toggle === "list") {
          renderProductions();
        } else {
          productionList.innerHTML = selectedList;
        }
      });

      // For mobile layout
      mobileFilter.forEach((filter) =>
        filter.addEventListener("click", () => {
          const filterButtonText = filter.querySelector(".Mobile-filter-text");
          document.body.classList.toggle("overflow-hidden");
          checkLists.forEach((list) =>
            list.classList.toggle("max-[768px]:hidden")
          );
          productionFilter.classList.toggle("Filter-mobile--layout");

          const status = filter.dataset.toggle;

          if (status === "close") {
            let numberOfSelections = 0;
            Object.keys(filterSelections).forEach((key) => {
              numberOfSelections += filterSelections[key].length;
            });
            indicatorTextMobile.innerHTML =
              numberOfSelections > 0 ? numberOfSelections : "";
            indicatorTextMobile.classList.add("Filter-indicator");
            filterButtonText.innerText = "Filtrera produktioner";
            filter.dataset.toggle = "open";
          } else if (status === "open") {
            renderProductions();
            indicatorTextMobile.classList.remove("Filter-indicator");
            filterButtonText.innerText = "Visa Resultat";
            filter.dataset.toggle = "close";
          }

          if (filtersElement.children.length) {
            filtersElement.classList.toggle("hidden");
          }
          clearMobileFilter.classList.toggle("hidden");
          mobileFilterMenu.classList.toggle("hidden");
          mobileFilterWrapper.classList.toggle("Mobile-filter--open");
          mobileFilterWrapper.parentElement.classList.toggle(
            "Mobile-filter--bottom"
          );
          const sliders = document.querySelectorAll(".Slider");
          sliders.forEach((slider) => {
            slider.classList.toggle("hidden");
          });
        })
      );
    });
});
