import gsap from "gsap";

let request = null;
const mouse = { x: 0, y: 0 };
const size = { w: 0, h: 0 };
let active = null;
let lastActive = null;

function update() {
  if (!active) return;

  const targets = Array.from(active.querySelectorAll(".mm-parallax"));
  targets.forEach((element) => {
    const { duration } = element.dataset;
    const value = element.dataset.parallaxValue;

    size.w = 0.5 * window.innerWidth;
    size.y = 0.5 * window.innerHeight;

    const x = 1.2 * value * (mouse.x - size.w);
    const y = 1.2 * value * (mouse.y - size.y);

    gsap.to(element, {
      duration: duration || 0.6,
      x,
      y,
      ease: "power2.out",
    });
  });
}

function handleMouseMove(event) {
  mouse.x = event.clientX;
  mouse.y = event.clientY;

  cancelAnimationFrame(request);
  request = requestAnimationFrame(update);
}

function handleResize() {
  // eslint-disable-next-line no-use-before-define
  invalidateEffectExistance();
}

function disable() {
  document.removeEventListener("mouseover", handleMouseMove);
  cancelAnimationFrame(request);

  const targets = Array.from(document.querySelectorAll(".mm-parallax"));
  targets.forEach((element) => {
    element.style.transform = `translate3d(0px, 0px, 0px)`;
  });

  window.removeEventListener("resize", handleResize, {
    passive: true,
  });

  // eslint-disable-next-line no-use-before-define
  window.addEventListener("resize", invalidateEffectExistance, {
    passive: true,
  });
}

function enable() {
  document.addEventListener("mousemove", handleMouseMove);
}

function invalidateEffectExistance() {
  if (window.innerWidth < 768) {
    disable();
  } else {
    enable();
  }
}

function reset() {
  if (!lastActive) return;
  const targets = Array.from(lastActive.querySelectorAll(".mm-parallax"));
  targets.forEach((element) => {
    gsap.to(element, {
      duration: 0.6,
      x: 0,
      y: 0,
      ease: "power2.out",
    });
  });
}

function observerCallback(entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      lastActive = active;

      active = entry.target;
    } else {
      reset();
    }
  });
}

export default function mouseMoveParallax(
  selector = ".MouseMoveParallax-wrapper"
) {
  const targets = Array.from(document.querySelectorAll(selector));
  if (!targets || targets.length === 0) {
    return;
  }

  const observerOptions = {
    rootMargin: "0px",
    threshold: 0.5,
  };

  const observer = new IntersectionObserver(observerCallback, observerOptions);
  targets.forEach((i) => {
    if (i) {
      observer.observe(i);
    }
  });

  invalidateEffectExistance();
}
