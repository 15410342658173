export default function slideshow() {
  const slideshows = Array.from(document.querySelectorAll(".Slideshow"));
  if (slideshows.length) {
    slideshows.forEach((slideshowContainer) => {
      const list = slideshowContainer.querySelector(".Slideshow-list");
      if (!list) return;

      const controls = slideshowContainer.querySelector(
        ".Slideshow-navigation"
      );
      const prevButton = controls.querySelector(
        ".Slideshow-navigation-button--prev"
      );
      const nextButton = controls.querySelector(
        ".Slideshow-navigation-button--next"
      );
      const items = slideshowContainer.querySelectorAll(".Slideshow-list-item");

      const pageSpan = controls.querySelector(".Slideshow-navigation-page");
      let currentPage = 1;
      pageSpan.innerHTML = `${currentPage} / ${items.length}`;

      if ("IntersectionObserver" in window) {
        const prevObserver = new IntersectionObserver(
          (entries) => {
            const first = entries[0];
            if (first && first.isIntersecting) {
              prevButton.classList.add("opacity-25");
              prevButton.disabled = true;
            } else {
              prevButton.classList.remove("opacity-25");
              prevButton.disabled = false;
            }
          },
          {
            root: list,
            threshold: [0.2],
          }
        );

        const nextObserver = new IntersectionObserver(
          (entries) => {
            const last = entries[0];
            if (last && last.isIntersecting) {
              nextButton.classList.add("opacity-25");
              nextButton.disabled = true;
            } else {
              nextButton.classList.remove("opacity-25");
              nextButton.disabled = false;
            }
          },
          {
            root: list,
            threshold: [0.2],
          }
        );
        prevObserver.observe(list.querySelector("li:first-child"));
        nextObserver.observe(list.querySelector("li:last-child"));

        const scrollHandler = (e) => {
          const atSnappingPoint =
            Math.round(e.target.scrollLeft) % e.target.offsetWidth === 0;
          const timeOut = atSnappingPoint ? 0 : 150;
          const page = Math.round(e.target.scrollLeft / list.clientWidth);
          clearTimeout(e.target.scrollTimeout);
          e.target.scrollTimeout = setTimeout(() => {
            if (!timeOut) {
              pageSpan.innerHTML = `${page + 1} / ${items.length}`;
            }
          }, timeOut);
        };

        list.addEventListener("scroll", scrollHandler);
      }

      controls.addEventListener("click", (e) => {
        if (e.target.closest("button")) {
          const button = e.target.closest("button");
          e.preventDefault();

          const listItem = document.querySelector(".Slideshow-list-item");
          const leftPos = button.value * listItem.offsetWidth;

          list.scrollBy({
            top: 0,
            left: leftPos,
            behavior: "smooth",
          });

          if (Math.sign(leftPos) === 1) {
            currentPage += 1;

            if (currentPage >= items.length) {
              currentPage = items.length;
            }
          } else {
            currentPage -= 1;
            if (currentPage <= 1) {
              currentPage = 1;
            }
          }

          pageSpan.innerHTML = `${currentPage} / ${items.length}`;
        }
      });
    });
  }
}
