export default function mobileMenu() {
  const mobileMenuItems = Array.from(
    document.querySelectorAll(".Mobile-MenuItems li")
  );
  if (mobileMenuItems.length) {
    // Close mobile menu if anchor link is clicked
    mobileMenuItems.forEach((item) => {
      item.addEventListener("click", (e) => {
        if (e.target.parentNode.classList.contains("menu-item-anchor-link")) {
          const menu = document.querySelector(".Menu-checkbox");
          menu.checked = false;
          document.body.classList.remove("overflow-hidden");
        }
      });
    });
  }
}
