import "./components/card-animation";
import "./components/image-list-scroll";
import "./components/production-filter";
import "./components/show-more";
import "./components/text-slider";
import "./components/toggle-menu";
import "./components/production-sidebar-anchors";
import "./components/card-click";

import setScrollbarWidth from "./utils/scrollbar";
import setContentHeight from "./utils/content-height";

import mouseMoveParallax from "./components/mouse-move-parallax";
import mobileMenu from "./components/mobile-menu";
import scrollParallax from "./components/scroll-parallax";
import slideshow from "./components/slideshow";
import stickyHeader from "./components/sticky-header";
import titleAnimation from "./components/title-animation";

window.addEventListener("DOMContentLoaded", () => {
  setContentHeight();
  setScrollbarWidth();

  mouseMoveParallax();
  mobileMenu();
  scrollParallax();
  slideshow();
  stickyHeader();
  titleAnimation();
});
