/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */

const callbacks = [];
let scrollTop = 0;

export default function scrollParallax(selector = ".ScrollParallax") {
  window.addEventListener("productionsUpdated", () => {
    disable();
    init(selector);
  });

  init(selector);
}

function init(selector) {
  const targets = Array.from(document.querySelectorAll(selector));

  if (!targets || targets.length === 0) {
    return;
  }

  targets.forEach((target) => {
    initializeParallax(target);
  });

  invalidateEffectExistance();
}

function range(value, inputRange, outputRange) {
  const p = (value - inputRange[0]) / (inputRange[1] - inputRange[0]);
  return outputRange[0] + p * (outputRange[1] - outputRange[0]);
}

function handleScroll() {
  scrollTop = document.documentElement.scrollTop;
  invalidate();
}

function handleResize() {
  invalidateEffectExistance();
  invalidate();
}

function invalidateEffectExistance() {
  if (window.innerWidth < 768) {
    disable();
  } else {
    enable();
  }
}

function invalidate() {
  for (let i = 0; i < callbacks.length; i++) {
    callbacks[i].update();
  }
}

function disable() {
  for (let i = 0; i < callbacks.length; i++) {
    callbacks[i].disableTarget();
  }
  document.removeEventListener("scroll", handleScroll);
  window.removeEventListener("resize", handleResize, {
    passive: true,
  });
  window.addEventListener("resize", invalidateEffectExistance, {
    passive: true,
  });
}

function enable() {
  for (let i = 0; i < callbacks.length; i++) {
    callbacks[i].enableTarget();
  }
  window.removeEventListener("resize", invalidateEffectExistance, {
    passive: true,
  });
  document.addEventListener("scroll", handleScroll);
  window.addEventListener("resize", handleResize, {
    passive: true,
  });
}

function initializeParallax(target) {
  const content = target.querySelector(".ScrollParallax-content");
  const scaleContent = target.querySelector(".ScrollParallax-scaleContent");
  if (!content) {
    return;
  }

  const getOffsetTop = () => {
    let t = 0;
    let e = target;
    while (e) {
      t += e.offsetTop;
      e = e.offsetParent;
    }
    return t;
  };
  let offsetTop = getOffsetTop();

  window.addEventListener(
    "resize",
    () => {
      offsetTop = getOffsetTop();
    },
    {
      passive: true,
    }
  );

  const { parallaxDistance, parallaxOffset } = target.dataset;
  const distance = Number(parallaxDistance);
  const offset = Number(parallaxOffset || 0);
  const transforms = {
    scale: 1,
    translateY: 0,
  };
  let disabled = false;

  if (scaleContent) {
    const bounds = content.getBoundingClientRect();
    const scale = Math.abs(distance) / bounds.height;
    transforms.scale = 1 + scale / 2;
  }

  const update = () => {
    if (disabled) {
      return;
    }
    const m = Math.max(window.innerHeight, target.offsetHeight);
    const p = range(
      -(
        offsetTop -
        window.innerHeight -
        scrollTop -
        offset * window.innerHeight +
        target.offsetHeight * offset
      ),
      [0, m],
      [0, 1]
    );
    transforms.translateY = -distance / 2 + (p * distance) / 2;
    content.style.transform = `translate3d(0, ${transforms.translateY}px, 0) scale(${transforms.scale})`;
  };

  const disableTarget = () => {
    content.style.transform = "";
    disabled = true;
  };

  const enableTarget = () => {
    disabled = false;
    update();
  };

  update();
  callbacks.push({ update, disableTarget, enableTarget });
}
