document.addEventListener("DOMContentLoaded", () => {
  const margin = 70;
  document.querySelectorAll(".card-container").forEach(async (row) => {
    const showMoreButton = row.querySelector(".Show-more");
    row.addEventListener("scroll", () => {
      if (
        showMoreButton.getBoundingClientRect().left +
          margin -
          window.innerWidth <
        0
      ) {
        showMoreButton.style.opacity = 1;
        showMoreButton.classList.add("animate");
      }
    });
  });
});
