import { startScrollSpy } from "@earthpeople/scroll-spy";

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".Cardrow").forEach(async (row) => {
    const cards = row.querySelectorAll(".Card");

    const handleScroll = () => {
      cards.forEach((card, index) => {
        if (index !== 0 && window.innerWidth > 768) {
          const distance = window.scrollY;
          card.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${
            -distance * 0.1 * index
          }, 0, 1)`;
        }
      });
    };

    const subscribe = startScrollSpy();
    subscribe("scroll", handleScroll);
  });
});
