import { startScrollSpy } from "@earthpeople/scroll-spy";

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".Image-list").forEach(async (list) => {
    const containerWidth = list.parentElement.getBoundingClientRect().width;
    const maxMargin = list.scrollWidth - containerWidth;

    const handleScroll = () => {
      const elementPosition = list.getBoundingClientRect();
      if (
        elementPosition.top < window.innerHeight &&
        elementPosition.bottom > 0 &&
        list.scrollWidth > window.innerWidth &&
        window.innerWidth > 768
      ) {
        const anchorPoint = elementPosition.top * 2;
        list.style.marginLeft = `-${
          maxMargin - anchorPoint < maxMargin
            ? maxMargin - anchorPoint
            : maxMargin
        }px`;
      } else {
        list.style.marginLeft = "0px";
      }
    };

    const subscribe = startScrollSpy();
    subscribe("scroll", handleScroll);
  });
});
