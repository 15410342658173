window.addEventListener("DOMContentLoaded", () => {
  const anchorLinksWrapper = document.getElementById(
    "production-sidebar-anchor-links"
  );
  if (!anchorLinksWrapper) return;

  let showWrapper = false;
  const links = anchorLinksWrapper.querySelectorAll("a");

  Array.from(links).forEach((link) => {
    const id = link.getAttribute("href").replace("#", "");
    if (document.getElementById(id)) {
      link.closest("li").classList.remove("hidden");
      showWrapper = true;
    }
  });

  if (showWrapper) anchorLinksWrapper.classList.remove("hidden");
});
