import { startScrollSpy } from "@earthpeople/scroll-spy";

export default function stickyHeader() {
  const menu = document.getElementById("cals-header");

  const setMenuSticky = (scrollTop) => {
    if (scrollTop < menu.offsetHeight) {
      menu.classList.remove("Header--sticky");
    } else {
      menu.classList.add("Header--sticky");
    }
  };

  const handleScroll = ({ scrollDirection, atTop, scrollTop }) => {
    const show = !!(scrollDirection === "up" || atTop);
    const position = show ? 0 : -menu.offsetHeight;
    menu.style.transform = `translateY(${position}px)`;

    setMenuSticky(scrollTop);
  };

  const scrollTop =
    window.pageYOffset !== undefined
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body)
          .scrollTop;

  setMenuSticky(scrollTop);

  const subscribe = startScrollSpy();
  subscribe("scroll", handleScroll);
}
