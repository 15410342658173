export default function titleAnimation(selector = ".TitleAnimation") {
  const targets = Array.from(document.querySelectorAll(selector));
  if (!targets || targets.length === 0) {
    return;
  }

  if ("IntersectionObserver" in window) {
    const observerOptions = {
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("TitleAnimation--visible");
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    document.querySelectorAll(selector).forEach((i) => {
      if (i) {
        observer.observe(i);
      }
    });
  }
}
